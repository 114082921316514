(function() {


  const layerTriggers = [].slice.call(document.querySelectorAll('[target="_layer"]'), 0);
  layerTriggers.forEach((trigger) => {
    trigger.addEventListener('click', (event) => {
      event.preventDefault();
      const layerId = trigger.getAttribute('href');
      document.querySelector(layerId).classList.add('active');
      
    })
  })
  const closeTrigger = [].slice.call(document.querySelectorAll('.close'), 0);
  closeTrigger.forEach((trigger) => {
    trigger.addEventListener('click', (event) => {
      event.preventDefault();
      document.querySelector('.layer.active').classList.remove('active');
    })
  });

  document.querySelector('form a.button').addEventListener('click', (event) => {

    event.preventDefault();
    var data = new FormData(document.querySelector('form'));

    var xhr = new XMLHttpRequest();
    //xhr.withCredentials = true;

    [].slice.call(document.querySelectorAll('.error'), 0).forEach((field) => field.classList.remove('error'));

    xhr.addEventListener("readystatechange", function() {
    if(this.readyState === 4) {
        if(xhr.response.success) {
          // success
          document.querySelector('form').classList.add('hide');
          document.querySelector('.successMessage').classList.add('active');
        }
        else {
          // error 
          xhr.response.error.forEach((error) => {
            document.querySelector('[name="'+ error + '"]').parentNode.classList.add('error');
          })
        }
        
    }
    });

    xhr.open("POST", '/server.php');
    xhr.responseType = 'json';

    xhr.send(data);

  })
})();